import React from "react";
import { connect } from 'react-redux';
import { Link, graphql } from "gatsby"
import _ from 'lodash';
import i18n from '../../i18n/i18n';

class FarmerIndex extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: this.props.data,
            params: this.props.params,
            moduli: [],
            lang: this.props.lang,
            loggedIn: this.props.loggedIn
        }
    }

    componentDidMount() {
        let moduli = JSON.parse(localStorage.getItem('moduli'));
        this.setState({ moduli });
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({
            loggedIn: nextProps.loggedIn,
            lang: nextProps.lang
        });
    }


    render() {
        const { edges: posts } = this.state.data.allMarkdownRemark;
        let resultModule = posts.filter((i) => i.node.frontmatter.lang == this.state.lang)
            .reduce((accumulator, currentValue) => {
                if (!accumulator.some((item) => {
                    if (item.node) {
                        return item.node.frontmatter.module === currentValue.node.frontmatter.module
                    }
                })) accumulator.push(currentValue);
                return accumulator;
            }, []);
        return (
            <div className="web-index" >
                {
                    _.chain(resultModule)
                        .sortBy(o => parseInt(o.node.frontmatter.formPriority))
                        .filter(post => post.node.frontmatter.title.length > 0)
                        .map(({ node: post }) => {
                            if (this.state.loggedIn && this.state.moduli && this.state.moduli.length > 0) {
                                if (this.state.moduli.some((element, i) => {
                                    return post.frontmatter.module.includes(element.naziv);
                                })) {
                                    return (
                                        <div className="web-index-li" key={post.id}>
                                            <Link to={'/farmer/' + post.frontmatter.module + this.props.location.search}>
                                                <img src={require("./images/" + post.frontmatter.module + ".png")} alt="post.frontmatter.img" />
                                                <h2>{i18n.t(`${post.frontmatter.module}_`)}</h2>
                                            </Link>

                                        </div>
                                    );
                                }
                                return null;
                            } else {
                                return (
                                    <div className="web-index-li" key={post.id}>
                                        <Link to={'/farmer/' + post.frontmatter.module + this.props.location.search}>
                                            <img src={require("./images/" + post.frontmatter.module + ".png")} alt="post.frontmatter.img" />
                                            <h2>{i18n.t(`${post.frontmatter.module}_`)}</h2>
                                        </Link>
                                    </div>
                                );
                            }
                        }).value()
                }
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        moduli: state.appReducer.moduli,
        lang: state.appReducer.lang,
        loggedIn: state.appReducer.loggedIn
    };
}
function mapDispatchToProps(dispatch) {
    return {
    };
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FarmerIndex);

export const pageQuery = graphql`
  query IndexQueryFarmer {
    allMarkdownRemark(sort: { order: ASC, fields: [frontmatter___priority] },
                    filter: { frontmatter:  { type: { eq:"FARMER"}}}) {
      edges {
        node {
          excerpt(pruneLength: 250)
          id
          frontmatter {
            title
            path
            lang
            type
            module
            priority
          }
        }
      }
    }
  }
`;